import axios from "axios";

// create base api
const api = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT || "",
});

let extraHeaders = { "Content-Type": "application/json" };
if ((process.env.VUE_APP_ENDPOINT || "").includes("ngrok")) {
  extraHeaders["ngrok-skip-browser-warning"] = "69420";
}
// set header from store on every request.
api.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    ...extraHeaders,
  };
  return config;
});

export { api as default };
