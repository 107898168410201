<template>
  <v-app>
    <div id="app" style="max-width: 100vw; overflow-x: hidden">
      <router-view />
    </div>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  methods: {
    ...mapActions({
      getServerStatus: "getServerStatus",
      updateScreenWidth: "updateScreenWidth",
    }),
    resizeHandler() {
      var w = document.documentElement.clientWidth;
      this.updateScreenWidth({ screenWidth: w });
    },
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  data() {
    return {
      status: null,
    };
  },
  mounted() {
    this.getServerStatus()
      .then((response) => {
        this.status = response;
      })
      .catch((error) => {
        this.status = error;
      });
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Roboto");
:root {
  --color-black-1: #4f4f4f;
  --color-blue-1: #1161f8;
  --color-blue-2: #08307c;
  --color-blue-4: #0c38c2;
  --color-pink-1: #ffd6d6;
  --color-pink-2: #c290f3;
  --color-red-1: #ff0c4f;
  --color-green-1: #9dffa7;
  --color-gray-1: #828282;
}
* {
  margin: 0;
  padding: 0;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
body {
  margin: 0;
}
html {
  scroll-behavior: smooth !important;
  box-sizing: border-box;
  width: 100%;
}
#app {
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #424242;
  position: relative;
}
.special-text {
  color: transparent;
  -webkit-text-stroke: 0.75px var(--color-red-1);
}
.special-text-blue {
  color: transparent;
  -webkit-text-stroke: 0.75px var(--color-blue-1);
}
.red {
  color: var(--color-red-1);
}
.blue {
  color: var(--color-black-1);
}
.bold {
  font-weight: 700;
}
.underline {
  text-decoration: underline;
}
.text-large-mobile {
  font-family: "Roboto";
  font-size: 40px;
  line-height: 40px;

  color: var(--color-black-1);
}
.text-big-mobile {
  font-family: "Roboto";
  font-size: 32px;
  line-height: 38px;

  color: var(--color-black-1);
}
.text-medium-mobile {
  font-family: Roboto;
  font-size: 15px;
  line-height: 22px;

  color: var(--color-black-1);
}
.text-small-mobile {
  font-family: Roboto;
  font-size: 12px;
  line-height: 15px;

  color: var(--color-black-1);
}
.text-tiny-mobile {
  font-family: Roboto;
  font-size: 10px;
  line-height: 15px;

  color: var(--color-black-1);
}

.flexible-ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.module {
  width: 100%;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.module-variant {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
@media (min-width: 740px) {
  .explore-button-text {
    font-size: 30px;
  }
  .text-large-desktop {
    font-size: 100px;
    line-height: 100px;
  }
  .text-big-desktop {
    font-size: 80px;
    line-height: 90px;
  }
  .text-medium-desktop {
    font-size: 30px;
    line-height: 40px;
  }
  .text-small-desktop {
    font-size: 20px;
    line-height: 30px;
  }
  .text-tiny-desktop {
    font-size: 15px;
    line-height: 22px;
  }
  .flexible-ul {
    table-layout: fixed;
    display: table;
    width: 100%;
  }
  .flexible-li {
    display: table-cell;
  }
  .module {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .module-variant {
    width: 80%;
    margin-left: 10%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .explore-button {
    width: 600px !important;
    margin: auto;
  }
  .hidable-br {
    display: none;
  }
}
.uppercase {
  text-transform: uppercase;
}
.relative {
  position: relative;
}
.z-upper {
  z-index: 2;
}
.z-lower {
  z-index: 1;
}
.explore-button {
  background: #ffffff;
  border: 2px solid var(--color-black-1);
  box-sizing: border-box;
  box-shadow: -4px 4px 10px rgba(17, 97, 248, 0.2);
  border-radius: 4px;
  margin-top: 20px;
  padding: 10px;
  display: block;
  width: 80%;
  margin-left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
}
.explore-button-text {
  font-family: Be Vietnam Pro;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--color-pink-1);
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: var(--color-blue-1);
  box-sizing: border-box;
  text-shadow: 0px 0px 2px var(--color-blue-1);
}
.upside-down {
  transform: scaleY(-1);
}
.be-vietnam-pro {
  font-family: Be Vietnam Pro;
}
.z-101 {
  z-index: 101;
  position: relative;
}

.font-staatliches {
  font-family: Staatliches;
}
.font-Roboto {
  font-family: Roboto;
}
.font-be-vietnam {
  font-family: Be Vietnam Pro;
}
.error-message {
  margin: auto;
  position: absolute;
  left: 50vw;
  top: 50vh;
  transform: translate(-50%, -50%);
  font-family: Roboto;
}
.setter {
  font-family: Roboto;
  position: fixed;
  z-index: 2;
  right: 10px;
  top: 10px;
  background: rgba(150, 150, 150, 50%);
  text-align: left;
  padding: 10px;
}

.section {
  padding: 15px 22px 15px 22px !important;
}
</style>
