var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loaded && _vm.uuid == null)?_c('div',[_c('iframe',{staticStyle:{"width":"100vw","height":"100vh"},attrs:{"src":"https://www.orientabot.co/form/","title":"Poll"}})]):_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",class:_vm.mobile ? 'flex-column' : 'flex-row',staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[_c('div',{staticClass:"me-8 ms-6 mt-6 text-left",class:_vm.mobile ? 'me-auto' : ''},[_c('img',{attrs:{"src":require("@/assets/YoPuedoSer.png"),"height":_vm.mobile ? 60 : 100,"aspect-ratio":"1"}})]),_c('div',{staticClass:"text-left pa-8 mb-5",staticStyle:{"border-radius":"50px 50px 0px 50px","background":"#f8f8f8 !important","color":"#6a6a6a"},style:(_vm.mobile
                ? 'margin-right:40px; padding-right: 80px !important; margin-top: 20px'
                : 'margin-right: 30%; margin-top: 80px;')},[_c('div',{staticClass:"text-heading"},[_vm._v("¡Hola estudiante!")]),_c('div',{staticClass:"text-heading"},[_vm._v(" Soy el "),_c('span',{staticStyle:{"color":"#ff3b8b"}},[_c('b',[_vm._v("OSO")]),_vm._v("bot")]),_vm._v(" de nuevo. ")]),_c('div',{staticClass:"mt-10"},[_vm._v(" En esta página web que creé para ti, puedes encontrar recursos muy valiosos para la toma de decisiones sobre tu futuro. Aquí tienes la posibilidad de explorar información sobre orientación vocacional, opciones de formación e incluso algunas becas disponibles para ti. ")])]),_c('img',{staticClass:"mt-auto ms-auto",staticStyle:{"position":"absolute","right":"0"},style:(_vm.mobile
                ? 'width: 120px; bottom: 30px'
                : 'max-width: 30%;bottom: 0; max-height: 400px'),attrs:{"src":_vm.mobile
                ? require('@/assets/Osobot-mobile.png')
                : require('@/assets/Osobot.png')}})])],1),_c('v-row',_vm._l((_vm.sites),function(data,index){return _c('v-col',{key:index,staticClass:"pa-12 d-flex flex-column",style:({ background: data.background }),attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"text-left",staticStyle:{"color":"white"},domProps:{"innerHTML":_vm._s(data.text)}}),_c('br'),_c('v-btn',{staticClass:"mt-auto text-none bold elevation-0 me-auto",staticStyle:{"min-width":"200px","min-height":"40px","border-radius":"15px","letter-spacing":"0px"},on:{"click":function($event){return _vm.openLink({ link: data.link, from: 'main' })}}},[_vm._v(_vm._s(data.buttonText))])],1)}),1),_c('v-row',[(_vm.mobile)?_c('v-col',{staticClass:"d-flex pt-4 pb-4 flex-column"},[_c('img',{staticClass:"ms-auto me-auto mb-4",staticStyle:{"max-width":"100px"},attrs:{"src":require("@/assets/FundacionSantoDomingo.png")}}),_c('img',{staticClass:"ms-auto me-auto mb-4",staticStyle:{"max-width":"100px"},attrs:{"src":require("@/assets/ConsiliumBots.png")}}),_c('img',{staticClass:"ms-auto me-auto mb-4",staticStyle:{"max-width":"100px"},attrs:{"src":require("@/assets/LogosEduc.png")}})]):_c('v-col',{staticClass:"d-flex pt-4 pb-4"},[_c('img',{staticClass:"ms-8'",staticStyle:{"max-height":"50px"},attrs:{"src":require("@/assets/FundacionSantoDomingo.png")}}),_c('img',{staticClass:"ms-8",staticStyle:{"max-height":"20px","margin-top":"20px"},attrs:{"src":require("@/assets/ConsiliumBots.png")}}),_c('img',{staticClass:"ms-auto me-8",staticStyle:{"max-height":"50px"},attrs:{"src":require("@/assets/LogosEduc.png")}})])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }