import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import vuetify from "./plugins/vuetify";

import "./styles/main.scss";
// vimeo
import vueVimeoPlayer from "vue-vimeo-player";
Vue.use(vueVimeoPlayer);
// smooth scroll
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll);

// mixpanel
import mixpanel from "mixpanel-browser";
// initialize mixpanel
mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
  debug: process.env.VUE_APP_MIXPANEL_DEBUG || false,
  ignore_dnt: true,
});

// papaparse
import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);

// cellhpone input
Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
