import Vue from "vue";
import Vuex from "vuex";
import mixpanel from "mixpanel-browser";
import services from "../services";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    screenWidth: 0,
    uuid: null,
  },
  getters: {
    mobile: ({ screenWidth }) => screenWidth <= 768,
    uuid: ({ uuid }) => uuid,
  },
  mutations: {
    updateScreenWidth(state, { screenWidth }) {
      state.screenWidth = screenWidth;
    },
    updateUuid(state, { uuid }) {
      state.uuid = uuid;
    },
  },
  actions: {
    getServerStatus() {
      return services.getServerStatus().then((response) => {
        return response;
      });
    },
    track({ getters }, { event, data }) {
      if (getters.uuid != null) {
        data["uuid"] = getters.uuid;
      }
      data["env"] = process.env.VUE_APP_ENVIRONMENT;
      mixpanel.track(event, data, () => {});
    },
    updateScreenWidth({ commit }, { screenWidth }) {
      commit("updateScreenWidth", { screenWidth });
    },
    updateUuid({ commit }, { uuid }) {
      commit("updateUuid", { uuid });
    },
    openLink({ dispatch }, { link, from }) {
      /* triggers an open link event. */
      dispatch("track", {
        event: `open-link-${from}`,
        data: {
          link,
        },
      });
      window.open(link, "_blank");
    },
    request(
      { commit },
      { endpoint, type, body, successMessage, errorMessage, params }
    ) {
      return services
        .request({ endpoint, type, body, params })
        .then((response) => {
          if (successMessage) {
            commit("setToastNotification", {
              toastState: true,
              toastMessage: successMessage,
            });
          }
          return response;
        })
        .catch((error) => {
          if (errorMessage) {
            commit("setToastNotification", {
              toastState: false,
              toastMessage: errorMessage,
            });
          }
          throw error;
        });
    },
  },
  modules: {},
});
