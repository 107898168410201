<template>
  <div>
    <!-- poll -->
    <div v-if="loaded && uuid == null">
      <iframe
        style="width: 100vw; height: 100vh"
        src="https://www.orientabot.co/form/"
        title="Poll"
      ></iframe>
    </div>
    <!-- site -->
    <div v-else>
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            class="d-flex"
            style="position: relative"
            :class="mobile ? 'flex-column' : 'flex-row'"
          >
            <div
              class="me-8 ms-6 mt-6 text-left"
              :class="mobile ? 'me-auto' : ''"
            >
              <img
                src="@/assets/YoPuedoSer.png"
                :height="mobile ? 60 : 100"
                aspect-ratio="1"
              />
            </div>

            <div
              style="
                border-radius: 50px 50px 0px 50px;
                background: #f8f8f8 !important;
                color: #6a6a6a;
              "
              :style="
                mobile
                  ? 'margin-right:40px; padding-right: 80px !important; margin-top: 20px'
                  : 'margin-right: 30%; margin-top: 80px;'
              "
              class="text-left pa-8 mb-5"
            >
              <div class="text-heading">¡Hola estudiante!</div>
              <div class="text-heading">
                Soy el <span style="color: #ff3b8b"><b>OSO</b>bot</span> de
                nuevo.
              </div>

              <div class="mt-10">
                En esta página web que creé para ti, puedes encontrar recursos
                muy valiosos para la toma de decisiones sobre tu futuro. Aquí
                tienes la posibilidad de explorar información sobre orientación
                vocacional, opciones de formación e incluso algunas becas
                disponibles para ti.
              </div>
            </div>
            <img
              :src="
                mobile
                  ? require('@/assets/Osobot-mobile.png')
                  : require('@/assets/Osobot.png')
              "
              class="mt-auto ms-auto"
              :style="
                mobile
                  ? 'width: 120px; bottom: 30px'
                  : 'max-width: 30%;bottom: 0; max-height: 400px'
              "
              style="position: absolute; right: 0"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pa-12 d-flex flex-column"
            cols="12"
            sm="6"
            v-for="(data, index) in sites"
            :key="index"
            :style="{ background: data.background }"
          >
            <div
              v-html="data.text"
              style="color: white"
              class="text-left"
            ></div>
            <br />
            <v-btn
              style="
                min-width: 200px;
                min-height: 40px;
                border-radius: 15px;
                letter-spacing: 0px;
              "
              class="mt-auto text-none bold elevation-0 me-auto"
              @click="openLink({ link: data.link, from: 'main' })"
              >{{ data.buttonText }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex pt-4 pb-4 flex-column" v-if="mobile">
            <img
              class="ms-auto me-auto mb-4"
              src="@/assets/FundacionSantoDomingo.png"
              style="max-width: 100px"
            />
            <img
              class="ms-auto me-auto mb-4"
              src="@/assets/ConsiliumBots.png"
              style="max-width: 100px"
            />
            <img
              class="ms-auto me-auto mb-4"
              src="@/assets/LogosEduc.png"
              style="max-width: 100px"
            />
          </v-col>
          <v-col v-else class="d-flex pt-4 pb-4">
            <img
              class="ms-8'"
              src="@/assets/FundacionSantoDomingo.png"
              style="max-height: 50px"
            />
            <img
              class="ms-8"
              src="@/assets/ConsiliumBots.png"
              style="max-height: 20px; margin-top: 20px"
            />
            <img
              class="ms-auto me-8"
              src="@/assets/LogosEduc.png"
              style="max-height: 50px"
            />
          </v-col>
        </v-row>
      </v-container>
      <!-- -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Application.vue",
  components: {},
  mounted() {
    if ("uuid" in this.$route.params) {
      if (this.$route.params.uuid == "terminos-y-condiciones") {
        this.$route.push({ name: "terms-and-conditions" });
      }
    }
    if (!("uuid" in this.$route.params) && !this.uuid) {
      window.location.replace(
        "https://yopuedoser.educacionbogota.edu.co/es/osobot-0"
      );
      this.loaded = true;
    } else {
      let uuid = this.$route.params.uuid;

      this.updateUuid({ uuid });
      this.track({ event: "open-site", data: {} });
      this.loaded = true;
    }
  },
  data() {
    return {
      loaded: false,
      sites: [
        {
          text: "En la página de <b>Yo puedo ser</b> de la Secretaría de Educación distrital de Bogotá, encontrarás guías sobre las diferentes ocupaciones y programas que la ciudad tiene por ofrecer. <b>“Mi Brújula hacia el futuro”</b> presenta seleccionadas opciones de ocupaciones y carreras, además de interesantes guías de orientación vocacional para aquellos que aún no tengan claro sus siguientes pasos.",
          link: "https://yopuedoser.educacionbogota.edu.co/es/yopuedoser",
          buttonText: "Ir a Yo Puedo Ser",
          background: "#FF3B8B",
        },
        {
          text: "En cuanto a las formas de cómo <b>financiar tus estudios</b> futuros, puedes revisar la página de Atenea, la Agencia Distrital para la Educación Superior, la Ciencia y la Tecnología de Bogotá. Esta es responsable de fomentar la articulación entre la educación media y posmedia. Aquí podrás explorar opciones de <b>becas o ayudas financieras</b> para cumplir tus metas de educación posmedia.",
          link: "https://agenciaatenea.gov.co/posmedia",
          buttonText: "Ir a Atenea",

          background: "#6F1FD8",
        },

        {
          text: "En la base de datos del Ministerio de Educación Nacional llamada SNIES, puedes encontrar toda la <b>oferta de programas de Educación Superior</b> disponibles en Bogotá y en toda Colombia, esta también incluye algunos detalles de los programas, como su <b>certificación</b> de calidad, la <b>duración</b> de cada programa y en algunos casos el <b>costo</b> de estos.",
          link: "https://snies.mineducacion.gov.co/portal/CONSULTAS-PUBLICAS/",
          buttonText: "Ir a SNIES",
          background: "#004268",
        },
        {
          text: "El Ministerio de Educación Nacional también tiene un sistema para explorar los diferentes programas de formación para el trabajo (SIET) que se ofrecen en Colombia. Aquí puedes buscar <b>programas técnicos laborales o de idiomas</b>, y conocer las instituciones que los ofrecen y más detalles de estos programas como su duración, requisitos y en algunos casos costos.",
          link: "https://www.mineducacion.gov.co/portal/micrositios-superior/Educacion-para-el-Trabajo-y-el-Desarrollo-Humano",
          buttonText: "Ir a SIET",
          background: "#00AEEF",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      openLink: "openLink",
      updateUuid: "updateUuid",
      track: "track",
    }),
  },
  computed: {
    ...mapGetters({
      mobile: "mobile",
      uuid: "uuid",
    }),
  },
};
</script>
