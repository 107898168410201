<template>
  <v-container
    fluid
    style="color: white; background: #004268"
    class="text-left"
  >
    <v-row class="pt-8 ps-8 pe-8">
      <img
        src="@/assets/YoPuedoSer.png"
        :height="mobile ? 60 : 100"
        aspect-ratio="1"
      />
      <img
        src="@/assets/OsobotFace.png"
        class="ms-auto"
        :height="mobile ? 60 : 100"
        aspect-ratio="1"
      />
    </v-row>
    <v-row class="mb-auto">
      <v-col cols="12" class="pa-12">
        <div class="text-title mb-8">Términos y Condiciones</div>
        <div class="text-subtitle mb-8">
          <b>OSObot</b>, es un asistente virtual que hace parte de un proyecto
          realizado por la
          <b>Secretaría de Educación Distrital (SED) de Bogotá</b> y la
          <b>ONG ConsiliumBots.</b>
        </div>
        <div class="text-body">
          • Con el ingreso al chat se autoriza el tratamiento de la información
          que usted le brinde a la herramienta.
          <br />
          • Dicho tratamiento se realizará exclusivamente para fines de
          investigaciones académicas y científicas por parte de la SED de Bogotá
          y los investigadores asociados de la ONG ConsiliumBots.
          <br />
          • Estas investigaciones tienen como propósito ser un aporte al diseño,
          construcción e implementación de estrategias en pro de mejorar el
          sistema educativo.
          <br />
          • La ONG Consiliumbots como aliado de la SED, está encargada del
          manejo y protección de los datos aquí recolectados.
          <br />
          • Para ello, la ONG garantiza el tratamiento de los datos en
          cumplimiento de los principios establecidos en el
          <b
            >Régimen General de Protección de Datos Personales y obligaciones
            establecidas en la Ley 1581 de 2012.</b
          >
        </div>
        <div class="mt-4 w-100 text-center">
          <v-btn
            rounded
            href="https://cb-colombia.s3.amazonaws.com/ProtocoloDatos.pdf"
            class="text-none"
          >
            Política de Datos de Consilium</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <custom-footer style="background: white" />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import CustomFooter from "@/components/CustomFooter.vue";
export default {
  name: "TermsAndConditions.vue",
  components: { CustomFooter },
  data() {
    return {
      pdfPath: "@/assets/ProtocoloDatos.pdf", // Adjust the path as needed
    };
  },
  computed: {
    ...mapGetters({
      mobile: "mobile",
    }),
  },
};
</script>
