<template>
  <v-row>
    <v-col class="d-flex pt-4 pb-4 flex-column" v-if="mobile">
      <img
        class="ms-auto me-auto mb-4"
        src="@/assets/FundacionSantoDomingo.png"
        style="max-width: 100px"
      />
      <img
        class="ms-auto me-auto mb-4"
        src="@/assets/ConsiliumBots.png"
        style="max-width: 100px"
      />
      <img
        class="ms-auto me-auto mb-4"
        src="@/assets/LogosEduc.png"
        style="max-width: 100px"
      />
    </v-col>
    <v-col v-else class="d-flex pt-4 pb-4">
      <img
        class="ms-8'"
        src="@/assets/FundacionSantoDomingo.png"
        style="max-height: 50px"
      />
      <img
        class="ms-8"
        src="@/assets/ConsiliumBots.png"
        style="max-height: 20px; margin-top: 20px"
      />
      <img
        class="ms-auto me-8"
        src="@/assets/LogosEduc.png"
        style="max-height: 50px"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CustomFooter.vue",

  computed: {
    ...mapGetters({
      mobile: "mobile",
    }),
  },
};
</script>
