import api from "../api";

export default {
  getServerStatus() {
    return api.get(`healthcheck`);
  },
  getStudent({ params, identifier }) {
    /* get student information */
    return api.get(`student/${identifier}`, { params });
  },
  getRegions({ params }) {
    /* gets available regions */
    return api.get("region_label", { params });
  },
  getAreas({ params }) {
    /* gets available areas */
    return api.get("area_label", { params });
  },
  getInstitutions({ params }) {
    /* gets available institutions */
    return api.get("institution", { params });
  },
  getPrograms({ params }) {
    /* gets available programs */
    return api.get("program", { params });
  },
  request({ endpoint, type, body, params }) {
    if (type == "post") {
      return api.post(endpoint, body);
    } else if (type == "get") {
      return api.get(endpoint, { params });
    } else if (type == "patch") {
      return api.patch(endpoint, body);
    } else if (type == "delete") {
      return api.delete(endpoint);
    }
  },
};
