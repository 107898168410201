import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import TermsAndConditions from "../views/TermsAndConditions.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/terminos-y-condiciones/",
    name: "terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    path: "/",
    name: "main2",
    component: Main,
  },
  {
    path: "/:uuid/",
    name: "main",
    component: Main,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
